import "../../css/Login.css";
import React, { useState, useEffect } from "react";
import { Button, Modal, Select } from "antd";
// import { logoutRetailer, retailerSession } from "../../reducer/retailerReducer";
import { useDispatch, useSelector } from "react-redux";
import headerLogo from "../../images/Retailer/homepage.png";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import RetailerEntitlement from "../../components/Modal/RetailerEntitlement";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import logoutBtn from "../../images/logout.png";
import RetailerTNC from "../../assets/2024-07-03 - Haleon Rewards Malaysia (Trade Partners) TCs_Final.pdf";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import axiosClientEncryption from "../../utils/axiosClientEncrytion";
import DraggableWhatsapp from "../../components/DraggableWhatsapp";
import { config, getCurrentConfigPhase } from "../../utilities/config";
import {  isWithinInterval } from "date-fns";

const FILTER_OVERVIEW = "All";
const FILTER_LAST_MONTH = "last month";
const FILTER_LAST_2_MONTH = "last 2 month";
const FILTER_LAST_3_MONTH = "last 3 month";

const FILTER_BY_LIST = [
  FILTER_OVERVIEW,
  FILTER_LAST_MONTH,
  FILTER_LAST_2_MONTH,
  FILTER_LAST_3_MONTH,
];

const { Option } = Select;

const RetailerHome = () => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { dashboardData, retailerClaimHistory, getQuarterlyEntitlement } =
    useSelector((state) => state.retailer);
  const [currentStoreId, setCurrentStoreId] = useState(dashboardData?.id);
  const [selectedFilterDate, setSelectedFilterDate] = useState(
    FILTER_BY_LIST[0]
  );
  const [proceed, setProceed] = useState(false);
  const [showQuarterlyEntitlement, setShowQuarterlyEntitlement] =
    useState(false);
  // const [viewClaim, setViewClaim] = useState(false);
  const [viewTNC, setViewTNC] = useState(false);
  const getRetailerToken = localStorage.getItem("retailer-token");
  const [retailerRanking, setRetailerRanking] = useState([]);
  const [recruitedUsers, setRecruitedUsers] = useState(0);
  const [totalEntitlement, setTotalEntitlement] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [retailerData, setRetailerData] = useState([]);
  const [retailerIds, setRetailerIds] = useState();
  const [selectedStore, setSelectedStore] = useState(null);
  const [channel, setChannel] = useState("");
  const [submissionEntryFound, setSubmissionEntryFound] = useState(false);
  const [userDetail, setUserDetail] = useState()
  const [selectedState, setSelectedState] = useState(null);

  const today = new Date('2024-08-15T00:00:00+08:00');
  const currentPhase = getCurrentConfigPhase(today, config);

  // default is previous month
  const getMonthOfStartAndEndDates = (_noOfAddMonth = 0) => {
    const currentDate = new Date();

    const prevMonth = addMonths(currentDate, _noOfAddMonth);
    const prevMonthOfStartDate = startOfMonth(prevMonth).toISOString();
    const prevMonthOfEndDate = endOfMonth(prevMonth).toISOString();

    return {
      startDate: prevMonthOfStartDate,
      endDate: prevMonthOfEndDate,
    };
  };

  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so January is 1, April is 4, July is 7, and October is 10.
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  const logOut = () => {
    localStorage.removeItem("retailer-token");
    navigate("/operator/login");
  };

  useEffect(() => {
    if (day >= 1 && day <= 15) {
      if (month === 1 || month === 2 || month === 3 || month === 10) {
        setShowQuarterlyEntitlement(true);
      } else {
        setShowQuarterlyEntitlement(false);
      }
    } else {
      setShowQuarterlyEntitlement(false);
    }
  }, [day, month]);

  const onChange = (dates) => {
    if (!dates || dates.length === 0) {
      setStartDate(null);
      setEndDate(null);
    } else {
      let startDate = new Date(dates[0]);
      let endDate = new Date(dates[1]);
      setStartDate((startDate));
      setEndDate((endDate));
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileRes = await axiosClientEncryption.get("/retailer/profile");
        profileRes?.data && setUserDetail(profileRes?.data)
        const retailerData = Array.isArray(profileRes?.data?.retailers)
          ? profileRes?.data?.retailers
              .map((retailer) => ({
                name: retailer?.name,
                id: retailer?.id,
                channel: retailer?.channel,
                extendedData: retailer?.extendedData,
                state: retailer?.state,
              }))
              .filter(
                (retailer) =>
                  // [2024-07-26] allow master retailer to be part of recruitment ranking
                  // !retailer?.name.includes("Master") &&
                  retailer.id
              )
          : [
              {
                name: profileRes?.data?.retailers[0]?.name,
                id: profileRes?.data?.retailers[0]?.id,
                channel: profileRes?.data?.retailers[0]?.channel,
                extendedData: profileRes?.data?.retailers[0]?.extendedData,
                state: profileRes?.data?.retailers[0]?.state,
              },
            ];
        setRetailerData(retailerData.map((retailer) => ({
            ...retailer,
            name:
              retailer?.channel !== "IP" &&
              retailer?.extendedData?.label !== null
                ? retailer?.extendedData?.label
              : retailer?.name
        })))
        setRetailerIds(retailerData[0]?.id);
        setChannel(profileRes?.data?.retailers[0]?.channel);
        localStorage.setItem(
          "channel",
          profileRes?.data?.retailers[0]?.channel
        );
        localStorage.setItem("retailer-id", retailerData[0]?.id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchRanking = async () => {
      if (retailerIds) {
        try {
          if (!currentPhase) {
            throw new Error("Current phase config not found");
          }
          const campaignId = currentPhase.campaignId;

          const checkRetailerRankingRes = await axiosClientEncryption.get(
            `/haleon/retailer/ranks/recruitment-users?retailerId=${retailerIds}`,
            {
              params: {
                campaignId,
                returnAllRetailers: retailerData.length > 1 ? true : false,
              },
            }
          );
          if (
            checkRetailerRankingRes &&
            checkRetailerRankingRes.data &&
            checkRetailerRankingRes.data.data
          ) {
            let getRetailerRank = checkRetailerRankingRes;
            setRetailerRanking(getRetailerRank);
          }
        } catch (err) {
          console.error("err", err);
        }
      }
    };

    const fetchCurrentCampaign = async () => {
      try {
        if (!currentPhase) {
          console.error("Current phase config not found");
        }
        const campaign = await axiosClientEncryption.get("/campaigns/" + currentPhase.campaignId);
        const entitlementReportPeriod = campaign?.data?.config?.entitlementReportPeriod
        if (isWithinInterval(new Date(), {
            start: new Date(entitlementReportPeriod.startAt),
            end: new Date(entitlementReportPeriod.endAt),
          })
        ) {
          setShowQuarterlyEntitlement(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchCurrentCampaign();
    fetchData();
    fetchRanking();
  }, [retailerIds]);

  useEffect(() => {}, [retailerIds]);

  useEffect(() => {
    fetchRecruitedUsers();
  }, [startDate, endDate, selectedStoreId, selectedState]);

  const fetchRecruitedUsers = async () => {
    try {
      const params = {
        createdAt: startDate,
        createdAtEnd: endDate,
      }
      const endpoint = `/haleon/retailer/recruited-users`

      if (selectedStoreId && selectedStoreId !== FILTER_OVERVIEW) {
        params.retailerId = selectedStoreId;
      } else {
        params.returnAllRetailers = true;
      }

      if (selectedState && selectedState !== FILTER_OVERVIEW) {
        params.state = selectedState;
      }

      const recruitedUsersRes = await axiosClientEncryption.get(
        endpoint,
        {
        params:
          // return value that are valid
          Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v != null)
          ),
        }
      );
      const noOftotalRecruitedUsers = recruitedUsersRes?.data?.data?.totalRecruitedUsers.reduce((acc, retailer) => acc + (retailer?.recruitedUsers?.length || 0), 0);
      setRecruitedUsers(noOftotalRecruitedUsers);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  const handleOnSelectStore = (value) => {
    if (value === FILTER_OVERVIEW) {
      setSelectedStoreId(FILTER_OVERVIEW);
    } else {
      const selectedStoreId = retailerData.find(
        (store) => `${store.extendedData?.label} | ${store.name}` === value
      )?.id;
      selectedStoreId && setSelectedStoreId(selectedStoreId);
    }
  };


useEffect(() => {
  const getQuarterlyEntitlement = async () => {
    if (retailerIds) {
      try {
        if (!currentPhase) {
          throw new Error("Current phase config not found");
        }

        const checkTotalEntitlementRes = await axiosClientEncryption.get(
          `/haleon/retailer/quarterly-entitlement?retailerId=${retailerIds}`,
          {
            params: {
              campaignId: currentPhase.campaignId,
              prevCampaignId: currentPhase.campaignId,
            },
          }
        );

        if (
          checkTotalEntitlementRes &&
          checkTotalEntitlementRes.data &&
          checkTotalEntitlementRes.data.data &&
          checkTotalEntitlementRes.data.data.length >= 1
        ) {
          const quarterlyDataArray = checkTotalEntitlementRes.data.data;
          let entitlement = 0;
            if (quarterlyDataArray.length === 1 && channel !== 'IP') {
            entitlement =
              month === 4
                  ? quarterlyDataArray[0]?.prevQuarterlyData
                      ?.totalEntitlementAmount
                : month === 7
                  ? quarterlyDataArray[0]?.currentQuarterlyData
                      ?.totalEntitlementAmount
                : 0;
          } else if (channel === 'IP') {
             entitlement = quarterlyDataArray[0]?.totalEntitlementAmount
          } else {
            entitlement = quarterlyDataArray.reduce((acc, quarterlyData) => {
              if (month === 4) {
                return (
                  acc +
                  quarterlyData?.prevQuarterlyData?.totalEntitlementAmount
                );
              } else if (month === 7) {
                return (
                  acc +
                  quarterlyData?.currentQuarterlyData?.totalEntitlementAmount
                );
              } else {
                return acc + quarterlyData?.totalEntitlementAmount;
              }
            }, 0);
          }
          
          setTotalEntitlement(entitlement);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  getQuarterlyEntitlement();
}, [month, retailerIds]);

  useEffect(() => { }, [totalEntitlement]);

  useEffect(() => {
    const getPaymentMethodInfo = async () => {
      try {
        if (!currentPhase) {
          throw new Error("Current phase config not found");
        }

        const campaignId = currentPhase.campaignId;
        const res = await axiosClientEncryption.get("/submissions", {
          params: { campaignId: campaignId },
        });
        if (
          res &&
          res.data &&
          res.data.getFinalSubmissionInfo &&
          res.data.getFinalSubmissionInfo.length > 0
        ) {
          const createdAtDates = res.data.getFinalSubmissionInfo.map((info) =>
            moment(info.getSubmissionInfo.createdAt).format("M")
          );
          const currentMonth = moment().format("M");
          const shouldProceed = !createdAtDates.includes(currentMonth);
          setSubmissionEntryFound(!shouldProceed);
          setProceed(shouldProceed);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getPaymentMethodInfo();
  }, []);

  const getRankSuffix = (rank) => {
    const lastDigit = rank % 10;
    const lastTwoDigits = rank % 100;

    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return "st";
    } else if (lastDigit === 2 && lastTwoDigits !== 12) {
      return "nd";
    } else if (lastDigit === 3 && lastTwoDigits !== 13) {
      return "rd";
    } else {
      return "th";
    }
  };

  const handleOnSelectState = (value) => {
    setSelectedState(value);
  };

  const presets = [
    {
      label: config.phase1.label,
      value: [
        dayjs(config.phase1.startDate),
        dayjs(config.phase1.endDate),
      ],
    },
    {
      label: config.phase2.label,
      value: [
        dayjs(config.phase2.startDate),
        dayjs(config.phase2.endDate),
      ],
    },
  ];

  const handlePresetChange = (value) => {
    const selectedPreset = presets.find(p => p.label === value);
    if (selectedPreset) {
      onChange(selectedPreset.value);
    }
  };

  return (
    <div style={{ paddingBottom: "5%", fontFamily: "Hero New, sans-serif" }}>
      <img src={headerLogo} alt="" className="ret-login-logo"></img>
      <img
        src={logoutBtn}
        alt="logout_btn"
        onClick={logOut}
        className="logout-button"
      />
      <DraggableWhatsapp />
      <div className="ml-2 mr-2 mt-3">
        <div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <h2
              style={{
              fontSize:'16px'
              }}
            > Hello, {userDetail?.name} </h2>
          </div>

          <br />
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: 'row',
                gap: '4px'
            }}>
              {retailerData.length > 1 && (
                < Select
                  showSearch
                  className="filter-selector"
                  onChange={handleOnSelectState}
                  options={[
                    {
                      value:FILTER_OVERVIEW,
                      label:FILTER_OVERVIEW,
                    },
                    ...retailerData
                      .filter((store) => store.state)
                      .map((store) => ({
                        value:  `${store.state}`,
                        label: `${store.state}`,
                      })),
                  ]}
                    placeholder={
                   'State: All'
                  }
                
                  style={{
                    flex:'1',
                    border: "2px solid black",
                    borderRadius: "5px",
                  }}
                  virtual={false}
              />)}

              <Select
                showSearch={
                  retailerData.length > 1 ? true : false
                  }
                disabled={
                  retailerData.length > 1 ? false : true
                }
                className="filter-selector"
                onChange={handleOnSelectStore}
                options={[
                      retailerData?.length > 1 ?  { value: FILTER_OVERVIEW, label: FILTER_OVERVIEW }:{},
                  ...retailerData.map((store) => ({
                        value:  `${store.extendedData?.label} | ${store.name}`,
                    label: `${store.extendedData?.label} | ${store.name}`,
                  })),

                ]}
                placeholder={
                      retailerData?.length > 1 && !selectedStoreId ?
                      'Store: All'
                      :
                    retailerData[0]?.channel !== "IP" &&
                      retailerData[0]?.extendedData?.label !== null
                    ? retailerData[0]?.extendedData?.label
                    : retailerData[0]?.channel !== "IP" &&
                      retailerData[0]?.extendedData?.label !== null
                    ? retailerData[0]?.name
                    : null
                }
                style={{
                    flex:'2',
                  border: "2px solid black",
                  borderRadius: "5px",

                }}
                virtual={false}
              />

              {/* <DatePicker.RangePicker
                defaultValue={[
                  dayjs(config.phase2.startDate),
              dayjs(config.phase2.endDate),
                ]}
                style={{
                    flex:'2',
                    border: "2px solid black",
                    borderRadius: "5px",
                  }}
                  size="medium"
                  suffixIcon={<DownOutlined />}
                  format="DD MMM YYYY"
                  // stay present 
                  presets={[
                    {
                      label: "Today",
                      value: [dayjs(), dayjs()],
                    },
                    {
                      "label": config.phase1.label,
                      "value": [
                        dayjs(config.phase1.startDate),
                        dayjs(config.phase1.endDate),
                      ],
                    },
                    {
                      "label": config.phase2.label,
                      "value": [
                        dayjs(config.phase2.startDate),
                        dayjs(config.phase2.endDate),
                      ],
                    },
                   /* The code is creating an array of objects with labels and values representing date
                   ranges. The first object represents "This Month" with a value of the start of the
                   current month to the current date. The second object represents "Last Month" with
                   a value of the start of the previous month to the current date. 

                    {
                      label: "This Month",
                      value: [dayjs().startOf("month"), dayjs()],
                    },
                    {
                      label: "Last Month",
                      value: [dayjs().subtract(1, "month"), dayjs()],
                    },
                    {
                      label: "Reset",
                      value: [dayjs(), dayjs()],
                    },
                  ]}
                  onChange={onChange}
                /> */}

              <Select
                    style={{ flex: '1', marginRight: '10px' }}
                placeholder="Select a date range"
                onChange={handlePresetChange}
              >
                    {presets.map(preset => (
                  <Option key={preset.label} value={preset.label}>
                    {preset.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        {/* Master & Store Account needs this */}
        <div
          style={{
            border: "1px solid black",
            padding: "5%",
            textAlign: "center",
            marginTop: "5%",
          }}
        >
          <div
            style={{ fontWeight: "500", fontSize: "20px", textAlign: "center" }}
          >
            Total User Recruited<sup>*</sup>
          </div>
          <div style={{ fontSize: "50px" }}>
            {recruitedUsers}
          </div>
          <div
            style={{ fontSize: "12px", fontWeight: "500", textAlign: "center" }}
          >
            <p>
              <sup>*</sup>Upon successful first submission
              <br /> (min spend RM30) of new user
              <br />
              (Please allow 3 working days of validation)
            </p>
          </div>
        </div>

        {/* Master Account */}
        {retailerData.length > 1 && (
          <div
            style={{
              border: "1px solid black",
              padding: "5%",
              textAlign: "center",
              marginTop: "5%",
            }}
          >
            <div
              style={{ fontWeight: "500", fontSize: "20px" }}
              className="text-center"
            >
              Total Entitlement
            </div>
            <div
              style={{
                fontSize: "48px",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              {recruitedUsers ? `RM${recruitedUsers * 5}`
                : `RM0`}
            </div>
          </div>
        )}

        {(retailerData[0]?.channel === "IP" ||
          retailerData[0]?.channel === "CARING" ||
          retailerData[0]?.channel === "AA" ||
          retailerData[0]?.channel === "BIG" ||
          retailerData[0]?.channel === "MULTICARE" ||
          retailerData[0]?.channel === "HEALTHLANE" ||
          retailerData[0]?.channel === "ALPRO") && (
          <div
            style={{
              border: "1px solid black",
              padding: "5%",
              textAlign: "center",
              marginTop: "5%",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              {retailerData[0]?.channel === "IP" ? (
                <>
                  Be the Top 3 Stores of the State to Win <b>RM500</b>
                </>
              ) : retailerData[0]?.channel === "CARING" ||
                retailerData[0]?.channel === "BIG" ? (
                <>
                  Be the Top 30 Stores to win <b>RM500</b>
                </>
              ) : retailerData[0]?.channel === "AA" ||
                retailerData[0]?.channel === "MULTICARE" ? (
                <>
                  Be the Top 10 Stores to win <b>RM500</b>
                </>
              ) : retailerData[0]?.channel === "HEALTHLANE" ? (
                <>
                  Be the Top 20 Stores to win <b>RM500</b>
                </>
              ) : retailerData[0]?.channel === "ALPRO" ? (
                <>
                  Be the Top 25 Stores to win <b>RM500</b>
                </>
              ) : null}
            </div>
            {retailerData?.length === 1 ? (
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  display: "flex",
                  marginTop: "4%",
                  alignItems: "center",
                }}
              >
                <p style={{ width: "50%", fontSize: "17px" }}>
                  Current Ranking
                </p>
                <div style={{ height: "35px", border: "1px solid black" }} />
                <p style={{ width: "50%" }}>
                  {retailerRanking?.data?.data?.retailersRank[0]?.rank ? (
                    <span style={{ fontSize: "25px", color: "black" }}>
                      {retailerRanking?.data?.data?.retailersRank[0]?.rank}
                      {getRankSuffix(
                        retailerRanking?.data?.data?.retailersRank[0]?.rank
                      )}
                    </span>
                  ) : (
                    "-"
                  )}{" "}
                  / {retailerRanking?.data?.data?.totalRetailers}
                </p>
              </div>
            ) : null}
            <Button
              style={{
                fontWeight: "700",
                background: "rgba(48, 234, 3, 1)",
                width: "100%",
                height: "38px",
                fontFamily: "Hero New, sans-serif",
                marginTop: "8%",
              }}
              className="fontSize-16"
              onClick={() => navigate("/retailer/ranking-overview")}
            >
              {retailerData.length > 1 ? "See Leaderboard" : "See More"}
            </Button>
          </div>
        )}

        {(retailerData.length > 1 || retailerData[0]?.channel === "IP") &&
          showQuarterlyEntitlement && (
            <div
              style={{
                height: "150px",
                borderRadius: "5px",
                border: "1px solid #000",
                background: "#333F48",
                marginTop: "12%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <div style={{ width: "60%", marginTop: "3%" }}>
                  <p
                    style={{
                      color: "#FFF",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Quarterly Entitlement
                  </p>
                  <p
                    style={{
                      color: "#FFF",
                      fontSize: "8.5px",
                      fontWeight: "300",
                      lineHeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Please confirm the details for the payout.
                  </p>
                  <p
                    style={{
                      color: "#FFF",
                      fontSize: "30px",
                      fontWeight: "500",
                      lineHeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    RM{totalEntitlement}
                  </p>
                </div>
                <div style={{ width: "40%", paddingTop: "5%" }}>
                  <Button
                    style={{
                      width: "110px",
                      height: "30px",
                      borderRadius: "5px",
                      background: "#FFF",
                      color: "black",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "normal",
                      textAlign: "center",
                      border: "none",
                    }}
                    onClick={() => navigate("/retailer/view/statement")}
                  >
                    View Statement
                  </Button>
                  <Button
                    style={{
                      width: "110px",
                      height: "30px",
                      borderRadius: "5px",
                      background: "#30EA03",
                      color: "black",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "normal",
                      textAlign: "center",
                      border: "none",
                      opacity:
                        totalEntitlement < 1 || submissionEntryFound
                          ? "0.5"
                          : "1",
                    }}
                    className="mt-1"
                    onClick={() => {
                      if (totalEntitlement < 1 || submissionEntryFound) {
                        setProceed(false);
                      } else {
                        setProceed(true);
                      }
                    }}
                  >
                    Agree & Proceed
                  </Button>
                </div>
              </div>
              <div>
                <p
                  style={{
                    fontSize: "9px",
                    color: "white",
                    textAlign: "center",
                    lineHeight: "1.5",
                    fontWeight: "300",
                    paddingTop: "4%",
                  }}
                >
                  The entitlement will be waived if there is no action taken by
                  22/{month}/{year}
                </p>
              </div>
            </div>
          )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "15%",
          }}
        >
          <a
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "400",
              textDecoration: "underline",
            }}
            className="text-center"
            href={RetailerTNC}
            target="_blank"
            rel="noopener noreferrer"
            // onClick={() => setViewTNC(true)}
          >
            Terms & Conditions
          </a>

          <a
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "400",
              textDecoration: "underline",
            }}
            className="text-center"
            href="https://www.privacy.haleon.com/en-my/general/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          {/* </div> */}
        </div>
      </div>

      <RetailerEntitlement setProceed={setProceed} proceed={proceed} />
    </div>
  );
};

export default RetailerHome;